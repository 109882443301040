<template>
    <Template
        v-bind="{
            ...params,
            isLoading,
        }"
        @onSubmit="() => onLogin()"
        @goTo="(path) => $router.push(path)"
    />
</template>
<script>
import Template from "../components/templates/Login/Login";
import { c } from "@/components/constants.js";
import { json } from "overmind";

export default {
    components: {
        Template,
    },
    methods: {
        async onLogin() {
            this.params.inputs.email.error = null;
            this.params.inputs.password.error = null;
            const forms = this.params.inputs;
            console.log(forms, "forms ====");
            if (!forms.email?.value) {
                this.params.inputs.email.error = "Please insert email!";
                return false;
            }
            if (!forms.password?.value) {
                this.params.inputs.password.error = "Please insert password!";
                return false;
            }
            try {
                this.isLoading = true;
                await this.actions.login({
                    email: forms.email?.value,
                    password: forms?.password?.value,
                });
                console.log(this.state.currentUser, "currentUser");
                if (
                    this.state.currentUser?.id &&
                    !this.state.currentUser?.emails?.[0]?.verified
                ) {
                    this.$router.push({
                        name: "Verify Email Step",
                        query: {
                            email: this.state.currentUser?.emails[0]?.address,
                        },
                    });
                    return false;
                }
                if (
                    this.state.currentUser?.groups?.find(
                        (g) => g?.name === "ecosystem-owner"
                    )
                ) {
                    if (!this.state.currentUser?.company?.id) {
                        this.$router.push({ name: "Register Company" });
                        return false;
                    }
                    if (this.state.currentUser?.paymentMethods?.length === 0) {
                        this.$router.push({ name: "Setup Payment" });
                        return false;
                    }
                } else {
                    // if (this.state.currentUser?.id && this.state.currentUser?.tags?.length === 0) {
                    // 	this.$router.push({ name: 'Demographic Information' });
                    // 	return false;
                    // }
                    if (
                        this.state.currentUser?.id &&
                        this.state.currentUser?.tags?.filter(
                            (t) => t?.category === "DESCRIPTORS"
                        )?.length === 0 &&
                        this.state.currentUser?.id &&
                        this.state.currentUser?.tags?.filter(
                            (t) => t?.category === "DESCRIPTORS"
                        )?.length === 0
                    ) {
                        this.$router.push({ name: "Interest And Activity" });
                        return false;
                    }
                }
                if (this.state.isLoggedIn) {
                    this.actions.alert.showSuccess({
                        message: "Logged In Successfully",
                    });
                    await this.actions.user.inviteUserAction({
                        route: this.$route,
                        user: this.state.currentUser,
                        noAlert: true,
                    });
                    await this.actions.user.getCurrentUser();
                    if (
                        this.state.currentUser?.groups?.find(
                            (g) => g?.name === "ecosystem-owner"
                        )
                    ) {
                        this.actions.setCurrentCompany(
                            json(this.state.currentUser?.company)
                        );
                        this.$router.push({
                            name: "Ecosystem Detail",
                            params: {
                                companyId: this.state.currentUser?.company?.id,
                            },
                        });
                    } else {
                        if (this.$route.query.v) {
                            this.$router.push({
                                name: "Venture Dashboard",
                                params: { ventureId: this.$route.query.v },
                            });
                        } else {
                            this.$router.push("/");
                        }
                    }
                    if (this.$route.query?.g) {
                        await this.actions.user.saveUser({
                            where: { id: this.state.currentUser?.id },
                            data: {
                                groups: {
                                    connect: [{ id: this.$route.query?.g }],
                                },
                            },
                        });
                    }
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isLoading = false;
            }
        },
    },
    data() {
        return {
            params: c.login,
            isLoading: false,
        };
    },
    mounted() {
        this.params.inputs.email.value = this.$route.query.e;
        this.params.inputs.password.value = null;
    },
};
</script>
