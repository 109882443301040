<template>
    <div class="grid w-full min-h-screen grid-cols-1 md:grid-cols-5">
        <div
            class="flex flex-col h-screen overflow-y-auto col-span-1 md:col-span-2"
        >
            <div class="flex-1 pt-10 sm:pt-20 px-3 sm:px-0">
                <div class="max-w-md px-2 mx-auto">
                    <img
                        src="../../../assets/images/dscvry_logo-purple.png"
                        class="h-6"
                    />
                    <Text
                        content="Welcome back!"
                        customClass="mt-8 tracking-tight"
                        weight="medium"
                        size="2xl"
                        color="gray-800"
                    />
                </div>
                <div class="flex flex-col mt-4">
                    <div
                        class="max-w-md px-2 mx-auto space-y-6 w-full flex flex-col"
                    >
                        <Text
                            content="Let's get you logged into your account"
                            color="gray-800"
                        />
                        <div class="space-y-4 rounded-md">
                            <Input
                                :isError="inputs.email.error"
                                :help-text="inputs.email.error"
                                id="email"
                                v-model:value="inputs.email.value"
                                :rounded="inputs.email.rounded"
                                :type="inputs.email.type"
                                :customClass="inputs.email.customClass"
                                :label="inputs.email.label"
                                :placeholder="inputs.email.placeholder"
                                @blur="validateEmail"
                            />
                            <PasswordInput
                                :isError="inputs.password.error"
                                :help-text="inputs.password.error"
                                id="password"
                                v-model:value="inputs.password.value"
                                :rounded="inputs.password.rounded"
                                :type="inputs.password.type"
                                :customClass="inputs.password.customClass"
                                :label="inputs.password.label"
                                :placeholder="inputs.password.placeholder"
                                :onEnterPressed="
                                    () => isValid && $emit('onSubmit')
                                "
                            />
                        </div>
                        <Text
                            @click="$emit('goTo', '/reset-password')"
                            content="Reset your password"
                            color="primary-600"
                            customClass="cursor-pointer underline text-sm mt-1 self-start"
                        />
                        <div class="flex justify-start max-w-md py-6">
                            <Button
                                :disabled="!isValid"
                                :content="button.label"
                                variant="primary"
                                :onClick="() => $emit('onSubmit')"
                                :is-loading="isLoading"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex-none">
                <div class="mb-20 sm:mb-0">
                    <div
                        class="max-w-md mx-auto my-6 px-5 sm:px-2 flex space-x-2"
                    >
                        <Text
                            content="Don't have an account yet?"
                            color="gray-800"
                            size="sm"
                        />
                        <Text
                            @click="
                                $emit('goTo', '/register-user?type=founder')
                            "
                            content="Sign Up"
                            color="primary-600"
                            customClass="cursor-pointer self-start flex underline text-sm"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="hidden h-full col-span-1 md:col-span-3 md:block">
            <img
                src="../../../assets/images/login_page_sidepanel.png"
                :alt="image.alt"
                class="h-screen w-full object-cover"
            />
        </div>
    </div>
</template>

<script>
/**
 - Combobox with text input to filter onh    basis of name property on default
 - give filter key to provide filter on a custom property
 **/
import Text from "../../atoms/Text/Text.vue";
import Link from "../../atoms/Link/Link.vue";
import Checkbox from "../../molecules/Inputs/Checkbox/Checkbox.vue";
import Input from "../../molecules/Inputs/Component/Component.vue";
import Button from "../../atoms/Button/Button.vue";
import Icon from "../../atoms/Icons/Icons.vue";
import PasswordInput from "../../molecules/Inputs/PasswordInput/PasswordInput.vue";

export default {
    emits: ["onChangeAgree"],
    components: {
        Text,
        Link,
        Checkbox,
        Input,
        Button,
        Icon,
        PasswordInput,
    },
    props: {
        image: {
            type: Object,
            default: () => {},
        },
        logo: {
            type: Object,
            default: () => {},
        },
        inputs: {
            type: Object,
            default: () => {},
        },
        button: {
            type: Object,
            default: () => {},
        },
        footerTitle: {
            type: String,
            default: "",
        },
        icons: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        inputs: {
            handler(val) {
                this.isValid =
                    /^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
                        val.password.value
                    ) &&
                    /^\w+([+\.-]?\w+)*@\w+([+\.-]?\w+)*(\.\w{2,})+$/.test(
                        val.email.value
                    );
            },
            deep: true,
        },
    },
    data() {
        return {
            isValid: false,
        };
    },
};
</script>
